@import "../../../../scss/_bootstrap-configuration.scss";

.ClientStatsBlock {
  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  border-style: solid;
  padding: 1rem;
}

.AsyncStats {
  // 3 rows tall when loading
  height: 3 * $line-height-base;
}
