@import "../../../../scss/_bootstrap-configuration.scss";
@import "../../../../scss/_layout.scss";

.ClientsStatsBlock {
  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  border-style: solid;
  padding: 1rem;

  .stats-col {
    @extend .spaced-children;
  }

  .list-download-link {
    text-align: left;
  }
}

.AsyncStats {
  // 2 row tall when loading
  height: 2 * $line-height-base;
}
