@import "../../../../scss/_bootstrap-configuration";
@import "../../../../scss/_layout";

.CandidateAdd {
  text-align: center;
  background-color: $light-background;

  // Center vertically
  height: 100%;
  @extend .center-vertically;

  .action img {
    height: 7rem;
  }
}
