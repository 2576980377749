// Fonts

// Set font-weight and font-style to normal so that bold and italic are not added in addition to the font-family
// when displaying user-defined html content (eg.: notes).

@font-face {
  font-family: 'camptonlight';
  src: url('../fonts/campton-cufonfonts/CamptonLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'camptonmedium';
  src: url('../fonts/campton-cufonfonts/CamptonMedium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'camptonsemibold';
  src: url('../fonts/campton-cufonfonts/CamptonSemiBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'camptonsemibolditalic';
  src: url('../fonts/campton-cufonfonts/CamptonSemiBoldItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'camptonextrabold';
  src: url('../fonts/campton-cufonfonts/CamptonExtraBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'camptonlightitalic';
  src: url('../fonts/campton-cufonfonts/CamptonLightItalic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

// Define font families with same default as the corporate website
$font-family-light: camptonlight, sans-serif;
$font-family-normal: camptonmedium, sans-serif;
$font-family-normal-italic: camptonlightitalic, sans-serif;
$font-family-bold: camptonsemibold, sans-serif;
$font-family-bold-italic: camptonsemibolditalic, sans-serif;
$font-family-bolder: camptonextrabold, sans-serif;
