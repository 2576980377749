@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_modal";
@import "../../../../scss/_layout";

.CandidateList {
  .candidates-row {
    @extend .wrap-spaced-children;
    justify-content: center;

    .candidate-col {
      @extend .col-auto;
    }
  }
}

.CandidateUpdateModal {
  .modal-dialog {
    // Make this modal larger
    @extend .modal-lg;
  }
}
