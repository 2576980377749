@import "../../../../scss/_fonts";

.QuestionnaireSearchResult {
  width: 100%;

  .name-col {
    font-family: $font-family-bold;
    text-align: left;
  }

  .question-col {
    text-align: right;
  }

  &.btn {
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
