@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_index";

.CandidatePreview {
  @extend .card-border;

  height: 100%;
  padding: 1rem;

  .name-row {
    // When name wraps on multiple line, icons must be aligned with first line
    align-items: flex-start;
  }

  .name-col {
    font-family: $font-family-bold;

    .name:hover {
      text-decoration: underline;
    }
  }

  .icon-col {
    @extend .col-auto;

    .icon-row {
      // Center all icons vertically among themselves, not with the candidate name
      align-items: center;
    }
  }

  .viewed-col {
    @extend .col-auto;
  }

  .delete-col {
    @extend .col-auto;
    color: $secondary;

    // Remove space between icon and right edge
    .btn {
      text-align: right;
    }

    // The right-align results in more space before the icon; compensate by removing the default column padding
    padding-left: 0;
  }

  .note-col {
    font-family: $font-family-normal-italic;
  }

  .completion-col {
    text-align: center;
  }
}
