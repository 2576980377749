@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_modal";
@import "../../../../scss/_layout";

.PoolCandidateList {
  .poolCandidates-row {
    @extend .wrap-spaced-children;
    justify-content: center;

    .poolCandidate-col {
      @extend .col-auto;
    }
  }
}

.PoolCandidateUpdateModal {
  .modal-dialog {
    // Make this modal larger
    @extend .modal-lg;
  }
}
