@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_layout";

.TalentPoolList {
  .talentPools-row {
    @extend .wrap-spaced-children;
    justify-content: center;

    .talentPool-col {
      @extend .col-auto;
    }
  }
}
