@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_layout";

.QuestionnaireDetails {

  @extend .spaced-children;

  .label {
    font-family: $font-family-bold;
    font-size: 1.2rem;
  }

  .ActionLink {
    text-align: left;
  }

}
