@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_layout";

.JobCategoryBlock {

  @extend .double-spaced-rows;

  .job-category-details, .candidates-list {
    @extend .simple-spaced-rows;
  }

  .title-row {
    align-items: baseline;
  }
}
