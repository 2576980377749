@import "../../../../scss/_fonts";
@import "../../../../scss/_layout";

.CandidateAnswer {
  @extend .simple-spaced-rows;

  .question-col {
    font-family: $font-family-bold-italic;
  }

  .answer-col {
    font-family: $font-family-light;
  }
}
