@import "../../../scss/_bootstrap-configuration";

.InputDateEditComponent {

  .btn-danger {
    color: white;

    &:hover, &:focus, &:active {
      color: white;
    }
  }

  .error {
    color: $danger;
  }
}
