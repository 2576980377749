@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_modal";
@import "../../../../scss/_layout";

.PoolJobList {
  .poolJobs-row {
    @extend .wrap-spaced-children;
    justify-content: center;

    .poolJob-col {
      @extend .col-auto;
    }
  }
}
