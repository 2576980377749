@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_modal";
@import "../../../../scss/_layout";

.JobCategoryList {
  .jobCategories-row {
    @extend .wrap-spaced-children;
    justify-content: center;

    .jobCategory-col {
      @extend .col-auto;
    }
  }
}
