.MenuItem {

  .active-item {
    font-weight: bold;
  }

  // We want the labels to have the same size even if they get bold on hover, because it
  // makes the other items move. We do this by overlapping a hidden component with same
  // text but already in bold. Text is passed through the title attribute.
  .same-width-on-hover {
    display: inline-block;

    &::before {
      display: block;
      content: attr(title);
      height: 0;
      overflow: hidden;
      visibility: hidden;

      @extend .active-item;
    }
  }

  .menu-item-link {

    // Highlight element when hovering, when on focus or when current page is the link target
    &:hover, &:focus {
      text-decoration: underline;
    }

    &:hover, &:focus, &.active {
      @extend .active-item;
    }
  }

  .menu-item-nav {
    padding: 0;
  }
}



