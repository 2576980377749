@import "../../../scss/_bootstrap-configuration";
@import "../../../scss/_bootstrap-utilities-api";

.TypeaheadEditComponent {

  .search-input-group {
    max-width: 500px;

    .search-input {
      // Make the input take all the available width of the flex container, up to the max-width of its parent
      flex-grow: 1;
    }

    .search-input-group-text {
      padding-left: 0;
      padding-right: 0;
    }

    .ActionLink {
      text-align: center;
    }

    .search-action {
      font-size: 0.8rem;
    }
  }

  .error {
    color: $danger;
  }
}
