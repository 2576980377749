@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.LastViewedList {

  .title-col {
    h2 {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
  }

  .empty-message {
    font-family: $font-family-normal-italic;
  }

  .list-item {

    // Remove gutters
    @extend .g-0;

    // Insert an horizontal line before each item
    border-top-style: solid;
    border-width: $border-width;

    // Insert an horizontal line after the last itme
    &:last-of-type {
      border-bottom-style: solid;
    }
  }
}
