.Menu {

  // Add space and "|" between menu items
  .MenuItem + .MenuItem {
    &::before {
      content: "|";
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  .MenuItem {
    .menu-item-link {
      text-transform: uppercase;
    }
  }
}



