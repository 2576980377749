@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_modal";

.ConfirmationModal {
  text-align: center;

  .modal-dialog {
    // Center dialog vertically
    @extend .modal-dialog-centered;
  }

  .modal-content {

    .modal-body {
      padding: 2rem;

      // Make some space between elements
      & > * + * {
        margin-top: 2rem;
      }

      .status-icon {
        height: 5rem;
      }

      .button-row {
        > * + * {
          // Space between buttons in a row
          margin-left: 1rem;
        }
      }
    }
  }
}
