@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_layout";

.PoolJobBlock {

  @extend .double-spaced-rows;

  .pool-job-details, .categories-list {
    @extend .simple-spaced-rows;
  }

  .title-row {
    align-items: baseline;
  }
}
