@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.ClientsBlock {

  .search-and-stats-block {
    margin-top: 4rem;
  }

  .search-block-row {
    text-align: center;
  }

  .create-link-row {
    // Add a small space to avoid focus box to overlap with previous row when button has focus
    margin-top: 2px;
  }

  .list-row {
    margin-top: 3rem;
  }

  .stats-col {
    @extend .col-auto;
  }
}
