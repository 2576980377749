@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.ClientBlock {

  .title-row {
    align-items: baseline;
  }

  .details-col {
    @extend .col-auto;

    // Make icon flush with right end
    .btn {
      padding-right: 0;
      text-align: right;
    }
  }

  .search-block-row {
    text-align: center;
  }

  .search-and-stats-block {
    margin-top: 4rem;
  }

  .create-link-row {
    // Add a small space to avoid focus box to overlap with previous row when button has focus
    margin-top: 2px;
  }

  .list-row {
    margin-top: 3rem;
  }

  .stats-col {
    @extend .col-auto;
  }
}
