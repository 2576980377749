@import "../../../scss/bootstrap-configuration";
@import "../../../scss/layout";

.ResumeDropbox.dropzone {
  text-align: center;
  padding: 2rem;
  border-width: 1px;
  border-color: $exotalent-blue;
  border-style: dashed;
  outline: none;

  &:focus {
    border-width: 4px;
  }

  &.disabled {
    background-color: $input-disabled-bg;
  }

  img {
    height: 5rem;
  }

  // Set height so that it does not change when displaying processing message
  .container {
    height: 20rem;
    @extend .center-vertically;
  }
}
