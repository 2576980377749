@import "../../../scss/_fonts";

.InlineEditInputBoolean {

  display: flex;

  .label {
    font-family: $font-family-bold;
  }

  .label + .action {
    margin-left: 0.5rem;
  }

}
