@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_modal";
@import "../../../../scss/_layout";

.ReportDetails {
  @extend .spaced-children;

  .label {
    font-family: $font-family-bold;
    font-size: 1.2rem;
  }

  .ActionLink {
    text-align: left;
  }
}

.EmailCreateModal {
  .modal-dialog {
    // Make this modal larger
    @extend .modal-xl;
  }
}
