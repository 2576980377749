@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_index";

.QuestionDetails {
  @extend .card-border;

  padding: 1rem;

  .mandatory-col {
    @extend .col-auto;
  }

  .drag-handle-col {
    @extend .col-auto;
  }

  .delete-col {
    @extend .col-auto;
    color: $secondary;

    // Remove space between icon and right edge
    .btn {
      text-align: right;
    }

    // The right-align results in more space before the icon; compensate by removing the default column padding
    padding-left: 0;
  }

  &.is-dragged {
    background-color: $light-background;
  }
}
