@import "../../../../scss/_fonts";
@import "../../../../scss/_layout";

.JobDetails {
  @extend .spaced-children;

  .label {
    font-family: $font-family-bold;
    font-size: 1.2rem;
  }

  .ActionLink {
    text-align: left;
  }
}
