@import "../../../../scss/_fonts";
@import "../../../../scss/_colors";

.ReportPublicLink {
  .unpublished-value {
    font-family: $font-family-light;
  }

  .copy-action, .copied-label {
    margin-left: 0.5rem;
  }

  .copied-label {
    color: $success-green;
    visibility: hidden;

    &.active {
      visibility: visible;
    }
  }
}
