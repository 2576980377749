@import "../../../../scss/_fonts";
@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.ReportSearchResult {
  width: 100%;

  .name-col {
    font-family: $font-family-bold;
    text-align: left;
  }

  .viewed-col {
    @extend .col-auto;
  }

  .report-col {
    text-align: right;
    @extend .col-auto;
  }

  &.btn {
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
