@import "./_fonts";

.form-with-rows {

  @extend .spaced-children;

  .form-label {
    font-family: $font-family-bold;

    // Override Bootstrap
    margin-bottom: 0;
  }

  .form-required {
    &:after {
      content: " *"
    }
  }

  .form-info {
    font-size: small;
    font-family: $font-family-light;
  }

  .form-actions {
    margin-top: 1rem;
    text-align: center;

    > * + * {
      // Space between buttons in a row
      margin-left: 1rem;
    }
  }
}
