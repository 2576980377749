@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_layout";

.ReportBlock {

  @extend .spaced-children;

  .title-row {
    align-items: baseline;
  }

  .viewed-col {
    @extend .col-auto;
  }

  .published-col {
    @extend .col-auto;
  }

  .details-col {
    @extend .col-auto;

    // Make icon flush with right end
    .btn {
      padding-right: 0;
      text-align: right;
    }
  }

  .search-block-row {
    margin-top: 4rem;
    text-align: center;
  }

  .list-row {
    margin-top: 3rem;
  }

  // Make the note area in view mode taller than one line
  .note-input .InlineEditInputTextArea .view .value > * {
    height: 5rem;
  }

  $edit-icon-width: 40px;

  .note-input {
    // Put the same white space on the left of the box than the space taken by the edit icon on the right.
    // We shouldn't use a fixed width though...
    margin-left: $edit-icon-width;
  }

  .note-input .edit {
    // When in edit mode, the edit icon is not there, but keep the same empty space
    margin-right: $edit-icon-width;
  }
}
