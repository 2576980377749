@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.QuestionnaireStats {

  font-family: $font-family-light;

  .label {
    margin-right: 1em;
  }

  .complete {
    color: $success-green;
  }

  .incomplete {
    color: $error-red;
  }

  .answer-count {
    font-family: $font-family-normal;
    font-size: 1.5em;
  }

}
