@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_layout";

.QuestionnaireBlock {

  @extend .simple-spaced-rows;

  .title-row {
    align-items: baseline;
  }

  .details-col {
    @extend .col-auto;

    // Make icon flush with right end
    .btn {
      padding-right: 0;
      text-align: right;
    }
  }

}
