@import "./_bootstrap-configuration";
@import "~bootstrap/scss/mixins/_buttons";

// Titles
h1, h2 {
  font-family: $font-family-bold;
  margin-bottom: 0; // Override bootstrap settings
}

// Buttons
.ActionButtonWithIcon {
  &.btn {
    border-width: 2px;
    font-family: $font-family-bold;
  }

  @include button-variant(
          $background: white,
          $border: $exotalent-orange,
          $color: $exotalent-blue,
          $hover-background: $exotalent-orange,
          $hover-color: white
  )
}

// Links
.ActionLink, a {
  font-family: $font-family-light;
  text-decoration-thickness: from-font;
}

// Menu items
.MenuItem {
  .active-item {
    // Overwrite the bold style for a different font family
    font-family: $font-family-bolder;
  }
}

// Card borders
.card-border {
  border-style: solid;
  border-color: $light-background;
  border-width: $border-width * 4;
}
