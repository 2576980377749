@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_layout";

.EmailCreateForm {

  $input-vspace: 0.5rem;

  // Make the message area in view mode as tall as when it is in edit mode
  .message-input .InlineEditInputTextArea .view .value > * {
    height: 10rem;
  }

  .address-row {
    // Add some space between From and To sections when they wrap on top of each other
    @extend .wrap-spaced-children;

    // Control the size of both columns because the wrapping may change when we edit a value and the inline edit component changes
    .from-section {
      @extend .col-12, .col-lg-6;
    }

    .from-name-row {
      margin-top: $input-vspace;
    }
  }

  .recipient-row + .recipient-row {
    margin-top: $input-vspace;
  }

  .to-add-label {
    font-size: 0.8em;
  }
}
