@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";

.InputTextAreaEditComponent {

  .textarea {
    height: 10rem;
  }

  .input-row {
    align-items: center;

    // Remove gutter, we'll set it manually
    @extend .g-0;
  }

  .button-row {
    justify-content: center;
  }

  .button-col {
    margin-top: 0.5rem;

    > * + * {
      margin-left: 0.5rem;
    }
  }

  // Override Bootstrap when it displays the button icon in black instead of white
  .btn-danger {
    color: white;

    &:hover, &:focus, &:active {
      color: white;
    }
  }

  .error {
    color: $danger;
  }
}
