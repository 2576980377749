@import "../../../../scss/_layout";

.QuestionList {

  @extend .simple-spaced-rows;

  .action-col {
    text-align: center;
  }
}
