@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_modals";
@import "../../../../scss/_layout";

.PoolCandidateUpdateForm {

  h1 {
    // Fix alignment problem with edit icon
    margin-bottom: 0;
  }

  .name-row + .contact-row {
    margin-top: 1rem;
  }

  .first-row {
    align-items: flex-start;
  }

  .contact-col {
    // Remove space to the left of links
    .btn {
      text-align: left;
    }

    // Add a small space between edit inputs so that they don't touch
    & > * + * {
      margin-top: 2px;
    }
  }

  .name-col {
    font-family: $font-family-bold;
  }

  .published-col {
    @extend .col-auto;
  }

  .resume-col {
    @extend .col-auto;
    padding: $modal-padding;

    @extend .spaced-children;

    // The background must touch the right upper edge of the modal
    margin-right: -$modal-padding + $grid-gutter-width/2;
    //margin-right: calc(#{-$modal-padding + $grid-gutter-width/2} + #{$border-width}); // 1 white pixel still appears
    margin-top: -$modal-padding;
    background-color: $dark-background;

    .resume-actions-col {
      // Remove space to the left of links
      .btn {
        text-align: left;
      }

      // All actions, active (clickable link) or inactive, must have the same font
      font-family: $font-family-light;
    }

    .resume-icon-col {
      img {
        // 3 lines of text
        height: 3 * $line-height-base * $font-size-base;
      }
    }

    .resume-row {
      // Center links vertically in case the box gets taller because of the contact infos
      height: 100%;
      align-items: center;
    }
  }
}
