@import "../../scss/_bootstrap-configuration";

.ResetPasswordPage {
  // Center items horizontally
  text-align: center;
  .row {
    justify-content: center;
  }

  .portal_logo {
    max-width: 20%;
  }
}

.ResetPasswordBlock {
  &.card {
    border-color: $exotalent-blue;
  }

  .card-body {
    color: $exotalent-blue;
    text-align: center;
    border-radius: $btn-border-radius;
  }
}
