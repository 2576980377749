@import "../../scss/_colors";
@import "../../scss/_bootstrap-configuration";


.Sidebar {
  border-right: solid;
  border-width: 2px;

  .inter-section-separator {
    margin: 0 1em 0 0;
    color: $secondary
  }

  .title-cell {
    color: $secondary;
  }
}
