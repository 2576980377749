@import "../../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../../scss/_index";

.JobCategoryPreview {
  @extend .card-border;

  height: 100%;
  // Padding bottom is equal to padding around delete button + height of delete button
  padding: 0.25em 0.25em 1.5em 0.25em;

  // content-row must stretch over all remaining vertical space with flex: 1
  display: flex;
  flex-direction: column;

  .delete-row {
    display: flex;
    justify-content: flex-end;
  }

  .content-row {
    flex: 1;
  }

  .content-col {
    // First line is pushed to the top and last line is pushed to the bottom
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .name-col {
    font-family: $font-family-bold;
    text-align: center;

    .name:hover {
      text-decoration: underline;
    }
  }

  .delete-col {
    @extend .col-auto;
    color: $secondary;

    // Remove space between icon and right edge
    .btn {
      text-align: right;
    }

    // The right-align results in more space before the icon; compensate by removing the default column padding
    padding-left: 0;
  }

  .candidate-col {
    text-align: center;
  }
}
