@import "../../../scss/_bootstrap-configuration";
@import "~bootstrap/scss/_grid";
@import "../../../scss/_layout";

.BasePage {

  // Give some air at the end of the page (there is no footer)
  padding-bottom: 4rem;

  .header-nav {
    margin-bottom: 2rem;
  }

  .left-sidebar-col {
    // We want the sidebar to be as large as the logo. We cannot set a width on the column but we can on its immediate children
    @extend .col-auto;
    & > * {
      width: $sidebar-width;
    }
  }

  .right-sidebar-col {
    // Same width as left-sidebar-col. Requires at least a child element
    @extend .left-sidebar-col;
  }
}
